




































import wAxios from '@/plugins/w/axios'
import { mapGetters, mapState } from 'vuex'

export default {

	components: {
		'a-exam-results': () => import('@/plugins/app@components/exam/a-exam-results.vue'),
		'z-loader': () => import('@/plugins/app@components/loader/z-loader.vue'),
	},

	data() {
		return {
			loading: false,
			results: null
		}
	},

	computed: {
		...mapState({
			testcode: (state: any) => state.test.code
		}),
		...mapGetters({
			testname: 'test/testname'
		})
	},

	async mounted() {
		try {
			this.loading = true
			const response = await wAxios.get_auth(
				`student/exams/${this.testcode}/student`,
				{ headers: { 'token': this.$store.state['user'].hash } }
			)
			this.results = response.data.data
		} catch (err) {
			await this.$wToast.error(err)
		} finally {
			this.loading = false
		}
	},

	methods: {
		back() {
			this.$store.commit('user/reset')
			this.$store.commit('test/infoReset')
			this.$router.push('/')
		}
	}
}
